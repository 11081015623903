<script lang="ts">
    import type { Snippet } from 'svelte'
    import type { HTMLAttributes } from 'svelte/elements'

    interface Props extends HTMLAttributes<HTMLElement> {
        href?: string
        active?: boolean
        image: Snippet
        content: Snippet
        target?: string
    }

    const {
        class: classNames = '',
        href = '',
        active = false,
        image,
        content,
        target = undefined,
        ...rest
    }: Props = $props()
</script>

{#if href}
    <a
        class="app-card {active ? 'active' : ''} {classNames}"
        {href}
        {...rest}
        {target}
    >
        <div class="app-card-image">
            {@render image()}
        </div>

        <div class="app-card-content">
            {@render content()}
        </div>
    </a>
{:else}
    <div class="app-card {classNames}" {...rest}>
        <div class="app-card-image">
            {@render image()}
        </div>

        <div class="app-card-content">
            {@render content()}
        </div>
    </div>
{/if}

<style global lang="postcss">
    .app-card {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        border: theme('borderWidth.DEFAULT') solid theme('colors.gray-200');
        border-radius: theme('borderRadius.DEFAULT');
    }

    .app-card-image img {
        max-width: 96px;
    }

    .app-card-content {
        width: 100%;
        padding: theme('spacing.4');
        overflow: hidden;
    }

    /* stylelint-disable-next-line */
    a.app-card:hover {
        border-color: theme('colors.green-500');
        background-color: theme('colors.white');
    }

    .app-card.active {
        border-color: theme('colors.green-800');
        background-color: theme('colors.white');
    }
</style>
